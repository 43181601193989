import axios from "axios";
import _ from "lodash";
import i18n from "@/plugins/i18n";
import store from "@/store";
import moment from "moment-timezone";

import {
  getUserInteractions,
  getUserProfile,
  getUserActions,
  getUserAssets
} from "@/api/user/traits.api";
import { getStakeholderAchievements } from "@/api/user/achievements.api";
import { getConstants } from "@/api/misc.api";
import { getUserBreaches } from "@/api/user/breaches.api";
import { extractValueFromProfile } from "@/utils/helpers";

const state = {
  rankings: [],
  user: {},
  interactions: [],
  cybersecurity: {},
  wellbeing: {},
  archetype: {},
  pwned: {},
  organization: {},
  progress: 0,
  pwnedStatus: 0,
  profile: [], // TODO: This is TEMP
  pills: [],
  customPills: [],
  assets: [],
  wallet: "",
  experience: "",
  status: "",
  breachesStatus: "",
  achievementsStatus: "",
  passworized: undefined,
  pendingInteractions: [],
  achievements: [],
  punctuations: []
};

const getters = {
  interactions: state => state.interactions,
  punctuations: state => state.punctuations,
  rankings: state => state.rankings,
  archetype: state => state.archetype,
  cybersecurity: state => state.cybersecurity,
  pills: state => state.pills,
  wellbeing: state => state.wellbeing,
  organization: state => state.organization,
  pwned: state => state.pwned,
  progress: state => state.progress,
  wallet: state => state.wallet,
  experience: state => state.experience,
  assets: state => state.assets,
  pendingInteractions: state => state.pendingInteractions,
  achievements: state => state.achievements,
  mods: () => {
    return [
      {
        id: "awareness",
        service: ["awareness"],
        image: "shield-protected.svg",
        color: "tc3",
        title: i18n.t("generic.modules.awareness.title"),
        description: i18n.t("generic.modules.awareness.description"),
        shortDesc: i18n.t("generic.modules.awareness.shortDesc"),
        to: { name: "Awareness" },
        family: "SURVEYTYPE_KYMATIO_CYBERSECURITY"
      },
      {
        id: "security",
        service: ["risk"],
        image: "mail-locked.svg",
        color: "tc1",
        title: i18n.t("generic.modules.security.title"),
        description: i18n.t("generic.modules.security.description"),
        shortDesc: i18n.t("generic.modules.security.shortDesc"),
        to: { name: "Security" },
        types: [
          "SURVEY_KYMATIO_GRI_CHALLENGE",
          "SURVEY_KYMATIO_GRI_COMMITMENT",
          "SURVEY_KYMATIO_GRI_TRUST",
          "SURVEY_KYMATIO_GRI_PRAGMATISM",
          "SURVEY_KYMATIO_GRI_DISSATISFACTION",
          "SURVEY_KYMATIO_GRI_NEGLIGENCE",
          "SURVEY_KYMATIO_GRI_EXPEDITION",
          "SURVEY_KYMATIO_GRI_OVERLOAD",
          "SURVEY_KYMATIO_GRI_DIVERGENCE",
          "SURVEY_KYMATIO_GRI_AMBITION",
          "SURVEY_KYMATIO_INSIDER_RED_REFRESH",
          "SURVEY_KYMATIO_INSIDER_RED",
          "SURVEY_KYMATIO_INSIDER_GREEN",
          "SURVEY_KYMATIO_INSIDER_GREEN_REFRESH",
          "SURVEY_KYMATIO_INSIDER_BLUE",
          "SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"
        ]
      },
      {
        id: "social",
        service: ["socialengineering"],
        image: "fingerprint.svg",
        color: "tc4",
        title: i18n.t("generic.modules.social.title"),
        description: i18n.t("generic.modules.social.description"),
        shortDesc: i18n.t("generic.modules.social.shortDesc"),
        to: { name: "SocialEngineering" },
        types: [
          "SURVEY_KYMATIO_ARCHETYPE",
          "SURVEY_KYMATIO_ARCHETYPE_REFRESH",
          "SURVEY_KYMATIO_ARCHETYPE_NLP"
        ]
      },
      {
        id: "archetype",
        service: ["archetype"],
        image: "position.svg",
        color: "tc6",
        title: i18n.t("generic.modules.archetype.title"),
        description: i18n.t("generic.modules.archetype.description"),
        shortDesc: i18n.t("generic.modules.archetype.shortDesc"),
        to: { name: "Archetype" },
        types: [
          "SURVEY_KYMATIO_ARCHETYPE",
          "SURVEY_KYMATIO_ARCHETYPE_REFRESH",
          "SURVEY_KYMATIO_ARCHETYPE_NLP"
        ]
      },
      {
        id: "impact",
        service: ["impact"],
        image: "server.svg",
        color: "tc5",
        title: i18n.t("generic.modules.impact.title"),
        description: i18n.t("generic.modules.impact.description"),
        shortDesc: i18n.t("generic.modules.impact.shortDesc"),
        to: { name: "Impact" },
        types: [
          "SURVEY_KYMATIO_IMPACT",
          "SURVEY_KYMATIO_IMPACT_REFRESH",
          "SURVEY_KYMATIO_INSIDER_RED",
          "SURVEY_KYMATIO_INSIDER_GREEN",
          "SURVEY_KYMATIO_INSIDER_RED_REFRESH",
          "SURVEY_KYMATIO_INSIDER_GREEN_REFRESH"
        ]
      },
      {
        id: "wellbeing",
        service: ["wellbeing"],
        image: "home-heart.svg",
        color: "tc2",
        title: i18n.t("generic.modules.wellbeing.title"),
        description: i18n.t("generic.modules.wellbeing.description"),
        shortDesc: i18n.t("generic.modules.wellbeing.shortDesc"),
        to: { name: "Wellbeing" },
        types: [
          "SURVEY_KYMATIO_CLIMATE_PLUS",
          "SURVEY_KYMATIO_CLIMATE_PLUS_BLUE",
          "SURVEY_KYMATIO_INSIDER_RED",
          "SURVEY_KYMATIO_INSIDER_GREEN",
          "SURVEY_KYMATIO_INSIDER_RED_REFRESH",
          "SURVEY_KYMATIO_INSIDER_GREEN_REFRESH"
        ]
      },
      {
        id: "custom_surveys",
        service: ["custom_surveys"],
        image: "companies.svg",
        color: "tc8",
        title: i18n.t("generic.modules.organization.title"),
        description: i18n.t("generic.modules.organization.description"),
        shortDesc: i18n.t("generic.modules.organization.shortDesc"),
        to: { name: "MyOrganization" },
        family: "SURVEYTYPE_KYMATIO_CSAAS",
        visible: false
      },
      {
        id: "burnout",
        service: ["burnout"],
        image: "fire.svg",
        color: "tc7",
        title: i18n.t("generic.modules.burnout.title"),
        description: i18n.t("generic.modules.burnout.description"),
        shortDesc: i18n.t("generic.modules.burnout.shortDesc"),
        to: { name: "Burnout" },
        types: ["SURVEY_KYMATIO_BURNOUT"]
      }
    ];
  },
  modules: () =>
    _.filter(store.getters["Profile/mods"], mod =>
      store.getters["Auth/hasModule"](mod.service)
    ),
  isProfileLoaded: state => state.status !== "",
  isAchievementsLoaded: state => state.achievementsStatus != "",
  isBreachesLoaded: state => state.breachesStatus !== "",
  pwnedStatus: state => {
    if (store.getters["Auth/hasModule"](["abs"])) {
      switch (state.pwnedStatus) {
        case 0:
          return "success";
        case 1:
          return "info";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "success";
      }
    } else {
      return false;
    }
  },
  pwnedLevel: state => {
    if (store.getters["Auth/hasModule"](["abs"])) {
      switch (state.pwnedStatus) {
        case 0:
          return 10;
        case 1:
          return 6.6;
        case 2:
          return 3.3;
        case 3:
          return 0;
        default:
          return false;
      }
    } else {
      return false;
    }
  },
  passworized: state => state.passworized || 0,
  alerts: state => {
    var result = [];
    if (state.pendingInteractions.length > 0) {
      var oldest = _.countBy(
        state.pendingInteractions,
        interaction =>
          moment().diff(moment(interaction.userStartDate), "months") >= 1
      );
      if (oldest.true > 0) {
        result.push({
          type: "interaction",
          level: "danger",
          levelNo: 0,
          total: oldest.true,
          icon: "shield-disabled.svg",
          to:
            (oldest.true || 0) + (oldest.false || 0) > 1
              ? { name: "Training" }
              : false,
          chat: (oldest.true || 0) + (oldest.false || 0) <= 1 ? {} : false,
          pending: oldest.false
        });
      }
      if (oldest.false > 0) {
        result.push({
          type: "interaction",
          level: "warning",
          levelNo: 1,
          total: oldest.false,
          icon: "warning-triangle.svg",
          to:
            (oldest.true || 0) + (oldest.false || 0) > 1
              ? { name: "Training" }
              : false,
          chat: (oldest.true || 0) + (oldest.false || 0) <= 1 ? {} : false,
          pending: oldest.true
        });
      }
    } else {
      result.push({
        type: "interaction",
        level: "success",
        levelNo: 3,
        total: 0,
        icon: "double-check.svg",
        to: { name: "Training" },
        pending: []
      });
    }
    var unsolved = state.passworized
      ? state.passworized.totalbreaches - state.passworized.totalbreachessolved
      : 0;

    var passwordUnsolved = state.passworized
      ? state.passworized.totalbreachespwd -
        state.passworized.totalbreachespwdsolved
      : 0;

    if (store.getters["Auth/hasModule"](["abs"])) {
      if (unsolved > 0 && passwordUnsolved > 0) {
        result.push({
          type: "breach",
          level: "danger",
          levelNo: 1,
          total: passwordUnsolved,
          icon: "shield-disabled.svg",
          to: { name: "Breaches" }
        });
      }
      if (unsolved > 0 && parseInt(unsolved) - parseInt(passwordUnsolved) > 0) {
        result.push({
          type: "breach",
          level: "warning",
          levelNo: 2,
          total: parseInt(unsolved) - parseInt(passwordUnsolved),
          icon: "warning-triangle.svg",
          to: { name: "Breaches" }
        });
      }
      if (
        state.passworized &&
        state.passworized.totalbreaches ===
          state.passworized.totalbreachessolved
      ) {
        if (state.passworized.totalbreaches === 0) {
          result.push({
            type: "breach",
            level: "success",
            levelNo: 3,
            total: 0,
            counter: 1,
            icon: "double-check.svg",
            chat: { bot: "breaches", forceStatus: "UNAVAILABLE" }
          });
        } else {
          result.push({
            type: "breach",
            level: "success",
            levelNo: 3,
            total: 0,
            counter: 2,
            icon: "double-check.svg",
            to: { name: "Breaches" }
          });
        }
      }
      if (!state.passworized) {
        result.push({
          type: "breach",
          level: "success",
          levelNo: 3,
          total: 0,
          counter: 1,
          icon: "double-check.svg",
          chat: { bot: "breaches", forceStatus: "UNAVAILABLE" }
        });
      }
    }

    var assetsPending = _.countBy(state.assets, asset =>
      parseInt(asset.totalReaded) === 0 ? asset.assetCount : false
    );
    if (assetsPending["2"] > 0) {
      result.push({
        type: "assets",
        level: "danger",
        levelNo: 1,
        total: assetsPending["2"],
        icon: "shield-disabled.svg",
        to: {
          name: "Assets",
          params: { status: "unreaded", priority: "priority" }
        }
      });
    }
    if (assetsPending["1"] > 0) {
      result.push({
        type: "assets",
        level: "warning",
        levelNo: 2,
        total: assetsPending["1"],
        icon: "warning-triangle.svg",
        to: {
          name: "Assets",
          params: { status: "unreaded", priority: "nopriority" }
        }
      });
    }
    if (assetsPending["2"] === 0 && assetsPending["1"] === 0) {
      result.push({
        type: "assets",
        level: "success",
        levelNo: 3,
        total: 0,
        icon: "double-check.svg",
        to: { name: "Assets" }
      });
    }

    return _.orderBy(result, ["levelNo", "total"], ["asc", "desc"]);
  },
  getNextIterationByModule: state => {
    return moduleId => {
      var selectedModule = _.find(
        store.getters["Profile/mods"],
        mod => mod.id === moduleId
      );
      var result = false;
      if (selectedModule && selectedModule.family) {
        result = _.findLast(
          _.orderBy(state.interactions, i => i.surveyEntityId, "asc"),
          e =>
            parseInt(e.surveyFamilyId) ===
            parseInt(
              store.getters["Constants/getConstant"](selectedModule.family)
            )
        );
      } else if (selectedModule) {
        result = _.findLast(
          _.orderBy(state.interactions, i => i.surveyEntityId, "asc"),
          e =>
            _.indexOf(
              _.map(selectedModule.types, type =>
                parseInt(store.getters["Constants/getConstant"](type))
              ),
              parseInt(e.surveyTypeId)
            ) !== -1
        );
      }
      return result;
    };
  },
  getInteractionsByModule: state => {
    return moduleId => {
      var selectedModule = _.find(
        store.getters["Profile/mods"],
        mod => mod.id === moduleId
      );
      var result = false;
      if (selectedModule && selectedModule.family) {
        result = _.filter(
          _.orderBy(state.interactions, i => i.surveyEntityId, "asc"),
          e =>
            parseInt(e.surveyFamilyId) ===
            parseInt(
              store.getters["Constants/getConstant"](selectedModule.family)
            )
        );
      } else if (selectedModule) {
        result = _.filter(
          _.orderBy(state.interactions, i => i.surveyEntityId, "asc"),
          e =>
            _.indexOf(
              _.map(selectedModule.types, type =>
                parseInt(store.getters["Constants/getConstant"](type))
              ),
              parseInt(e.surveyTypeId)
            ) !== -1
        );
      }
      return result;
    };
  },
  sessionStatus: state => {
    var type = "done";
    var result = _.first(
      _.orderBy(
        state.pendingInteractions.length > 0 ? state.pendingInteractions : []
      ),
      "userStartDate",
      "desc"
    );
    if (!result) {
      type = "soon";
      result = _.first(
        _.orderBy(
          _.filter(
            state.interactions,
            interaction =>
              moment(interaction.userStartDate).diff(moment(), "weeks") < 1 &&
              (parseInt(interaction.surveyFamilyId) ===
                parseInt(
                  store.getters["Constants/getConstant"](
                    "SURVEYTYPE_KYMATIO_DIRECT"
                  )
                ) ||
                parseInt(interaction.surveyFamilyId) ===
                  parseInt(
                    store.getters["Constants/getConstant"](
                      "SURVEYTYPE_KYMATIO_CYBERSECURITY"
                    )
                  ) ||
                parseInt(interaction.surveyFamilyId) ===
                  parseInt(
                    store.getters["Constants/getConstant"](
                      "SURVEYTYPE_KYMATIO_CLIMA_PLUS"
                    )
                  ) ||
                parseInt(interaction.surveyFamilyId) ===
                  parseInt(
                    store.getters["Constants/getConstant"](
                      "SURVEYTYPE_KYMATIO_WELCOME"
                    )
                  ))
          ),
          "userStartDate",
          "desc"
        )
      );
    }
    if (!result) {
      type = "done";
      result = _.first(
        _.orderBy(
          _.filter(
            state.interactions,
            interaction => _.toLower(interaction.surveyStatus) == "finish"
          ),
          "userStartDate",
          "desc"
        )
      );
    }
    if (result) {
      var resultIteration = result;
      result = {
        date: moment(resultIteration.dateStatus).format("ll"),
        title: resultIteration.surveyName,
        type: type,
        raw: resultIteration
      };
    }
    return result;
  }
};

const actions = {
  loadAchievements({ commit, rootGetters }) {
    let profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GAMING")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WALLET")
      ]
    };

    let requests = [
      getUserProfile(profile),
      getStakeholderAchievements(),
      getStakeholderAchievements(true)
    ];

    if (_.isEmpty(state.interactions)) {
      requests.push(getUserInteractions());
    }

    if (store.getters["Auth/hasModule"](["gaming_achievements"])) {
      axios.all(requests).then(
        axios.spread(
          (
            profileResp,
            achievementsResponse,
            punctuationsResponse,
            interactionsResp
          ) => {
            let interactions =
              interactionsResp != undefined
                ? interactionsResp.data.records
                : state.interactions;
            commit("set_achievements", {
              profile: profileResp.data.records,
              achievements: achievementsResponse.data.records,
              punctuations: punctuationsResponse.data.records,
              interactions: interactions
            });
          }
        )
      );
    }
  },
  loadProfile({ commit, rootGetters }, retryAchievemnts = false) {
    var dimensions = {
      description: rootGetters["Constants/getConstant"](
        "ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"
      ),
      motivation: rootGetters["Constants/getConstant"](
        "ACTIONS_TYPE_ARCHETYPES_MOTIVATION"
      ),
      work: rootGetters["Constants/getConstant"](
        "ACTIONS_TYPE_ARCHETYPES_WORK"
      ),
      strengths: rootGetters["Constants/getConstant"](
        "ACTIONS_TYPE_ARCHETYPES_STRENGTHS"
      ),
      challenges: rootGetters["Constants/getConstant"](
        "ACTIONS_TYPE_ARCHETYPES_CHALLENGES"
      )
    };
    var actionParams = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ARCHETYPES")
      ],
      actionTypes: [
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"
        ),
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ARCHETYPES_MOTIVATION"
        ),
        rootGetters["Constants/getConstant"]("ACTIONS_TYPE_ARCHETYPES_WORK"),
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ARCHETYPES_STRENGTHS"
        ),
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ARCHETYPES_CHALLENGES"
        )
      ],
      entityType: rootGetters["Constants/getConstant"]("ENTITY_TYPE_USER"),
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE_GLOBAL")
      ]
    };
    var profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_GAMING"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_RED"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_GREEN"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_INSIDER_BLUE"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE_NLP"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_IMPACT"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_SCORE_GLOBAL"),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"
        ),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_ARCHETYPE_REFRESH"
        ),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_IMPACT_REFRESH"),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_CLIMATE_GLOBAL"),
        rootGetters["Constants/getConstant"](
          "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL"
        ),
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_BREACH_CORPORATE")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_RANKING"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_EXTRAS"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_WORKING"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CIBERSECURITY"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_SCORING"),
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ABS")
      ]
    };
    var cyberProfile = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CIBERSECURITY")
      ]
    };
    var pillsParams = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_INSIDER")
      ],
      actionTypes: [rootGetters["Constants/getConstant"]("ACTIONS_TYPE_PILLS")],
      entityType: rootGetters["Constants/getConstant"]("ENTITY_TYPE_USER")
    };
    var customSurveysProfile = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CUSTOM")
      ],
      maxValue: "NO",
      dateFrom: "1970-01-01",
      dateTo: "now"
    };
    var customPillsParams = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_CUSTOM")
      ],
      surveyFamilies: [
        rootGetters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_CSAAS")
      ]
    };
    axios
      //NOTA:
      //Control con hasModule para evitar hacer petis al back innecesarias?
      //Actualemente parece que se relaicen todas las llamadas indiferentemete de si se tiene el modulo o no
      .all([
        getUserProfile(profile),
        getUserProfile(cyberProfile),
        getUserProfile(customSurveysProfile),
        getUserActions(actionParams),
        getUserInteractions(),
        getUserActions(pillsParams),
        getUserActions(customPillsParams),
        getConstants("SURVEY_KYMATIO_CYBERSECURITY"),
        getConstants("SURVEY_KYMATIO_CUSTOM_SURVEY"),
        getUserAssets()
      ])
      .then(
        axios.spread(
          (
            profileResp,
            cyberProfileResp,
            customResp,
            actionsResp,
            interactionsResp,
            pillsResp,
            customPillsResp,
            cyberSurveysNamesResp,
            customSurveysNamesResp,
            assetsResp
          ) => {
            commit("set_interactions", interactionsResp.data.records);
            commit("set_profile", {
              data: profileResp.data.records,
              cyber: cyberProfileResp.data.records,
              interactions: interactionsResp.data.records,
              surveysNames: _.keys(cyberSurveysNamesResp.data.records),
              customSurveysNames: _.keys(customSurveysNamesResp.data.records),
              assets: assetsResp.data.records,
              customSurveys: customResp.data.records
            });
            commit("set_archetype", {
              actions: actionsResp.data.records,
              dimensions: dimensions
            });
            commit("set_pills", pillsResp.data.records);
            commit("set_custom_pills", customPillsResp.data.records);
            commit("set_done");

            if (retryAchievemnts) {
              this.loadAchievements;
            }
          }
        )
      );
  },
  resetBreaches({ commit }) {
    commit("resetBreaches");
  },
  reloadBreaches({ commit, rootGetters }) {
    commit("resetBreaches");
    var profile = {
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_BREACH_CORPORATE")
      ],
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ABS")
      ]
    };
    axios.all([getUserProfile(profile), getUserBreaches()]).then(
      axios.spread((profileResp, pwnedResp) => {
        commit("set_breaches", {
          data: profileResp.data.records,
          pwned: pwnedResp.data.records
        });
      })
    );
  },
  reloadInteractions({ commit }) {
    getUserInteractions().then(response => {
      commit("set_interactions", response.data.records);
    });
  },
  reloadAssets({ commit }) {
    getUserAssets().then(response => {
      commit("set_assets", response.data.records);
    });
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_assets(state, data) {
    state.assets = data;
  },
  set_achievements(state, data) {
    state.achievements = data.achievements;
    state.punctuations = data.punctuations;
    state.wallet = extractValueFromProfile(
      data.profile,
      "DIMENSION_GROUP_WALLET",
      "SURVEY_KYMATIO_GAMING",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_WALLET_CREDIT"
    );
    state.experience = extractValueFromProfile(
      data.profile,
      "DIMENSION_GROUP_WALLET",
      "SURVEY_KYMATIO_GAMING",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_POINTS_EXPERIENCE"
    );
    state.achievementsStatus = "success";
  },
  set_profile(state, data) {
    state.profile = data.data;
    state.assets = data.assets;
    let rankings = [];
    let rankingAssets = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_RANKING",
      "SURVEY_KYMATIO_GAMING",
      "dimensionValueExtra",
      data.interactions,
      "DIMENSION_ELEMENT_RANKING_ASSETS"
    );
    let rankingGlobal = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_RANKING",
      "SURVEY_KYMATIO_GAMING",
      "dimensionValueExtra",
      data.interactions,
      "DIMENSION_ELEMENT_RANKING_GLOBAL_NOTE"
    );
    let rankingCyber = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_RANKING",
      "SURVEY_KYMATIO_GAMING",
      "dimensionValueExtra",
      data.interactions,
      "DIMENSION_ELEMENT_RANKING_CIBER_RESULTS"
    );
    if (rankingAssets) {
      rankings.push({
        name: "assets",
        value: rankingAssets
      });
    }
    if (rankingGlobal) {
      rankings.push({
        name: "global",
        value: rankingGlobal
      });
    }
    if (rankingCyber) {
      rankings.push({
        name: "cyber",
        value: rankingCyber
      });
    }
    state.rankings = rankings;
    state.progress = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_SCORING",
      "SURVEY_KYMATIO_SCORE_GLOBAL",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_KYMATIO_GLOBAL_NOTE"
    );
    state.cybersecurity.average = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_CIBERSECURITY",
      "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_CIBER_RESULTS"
    );

    state.cybersecurity.series = [];
    state.cybersecurity.labels = [];
    state.cybersecurity.dates = [];
    state.cybersecurity.discrete = [];
    let lastElement = 0;
    _.each(data.surveysNames, survey => {
      if (
        survey !== "SURVEY_KYMATIO_CYBERSECURITY_GLOBAL" &&
        store.getters["Constants/getConstant"](survey)
      ) {
        const label = _.find(data.interactions, e => {
          return (
            parseInt(e.surveyTypeId) ===
            parseInt(store.getters["Constants/getConstant"](survey))
          );
        });
        const element = extractValueFromProfile(
          data.cyber,
          "DIMENSION_GROUP_CIBERSECURITY",
          survey,
          "dimensionValue",
          data.interactions,
          "DIMENSION_ELEMENT_CIBER_RESULTS"
        );
        if (element !== false && element !== undefined) {
          state.cybersecurity.series.push(element);
          state.cybersecurity.labels.push(label ? label.surveyName : "");
          state.cybersecurity.dates.push(
            label ? moment(label.dateStatus).format("ll") : false
          );
          lastElement = element;
        }
      }
    });
    if (state.cybersecurity.series.length === 1) {
      state.cybersecurity.series.unshift(lastElement);
      state.cybersecurity.labels.unshift("");
      state.cybersecurity.dates.unshift(null);
      state.cybersecurity.series.push(lastElement);
      state.cybersecurity.labels.push("");
      state.cybersecurity.dates.push(null);
      state.cybersecurity.discrete = [
        {
          seriesIndex: 0,
          dataPointIndex: 0,
          size: 0
        },
        {
          seriesIndex: 0,
          dataPointIndex: 2,
          size: 0
        }
      ];
    }
    state.wellbeing.average = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_WORKING",
      "SURVEY_KYMATIO_CLIMATE_GLOBAL",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_CLIMATE_LEVEL"
    );
    state.pwnedStatus = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_ABS",
      "SURVEY_KYMATIO_BREACH_CORPORATE",
      "dimensionValue",
      data.interactions,
      "DIMENSION_ELEMENT_BREACH_LEVEL"
    );
    state.passworized = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_ABS",
      "SURVEY_KYMATIO_BREACH_CORPORATE",
      "dimensionValueExtra",
      data.interactions,
      "DIMENSION_ELEMENT_BREACH_TOTAL",
      null,
      true
    );
    state.pwned = data.pwned;
  },
  set_breaches(state, data) {
    state.pwnedStatus = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_ABS",
      "SURVEY_KYMATIO_BREACH_CORPORATE",
      "dimensionValue",
      state.interactions,
      "DIMENSION_ELEMENT_BREACH_LEVEL",
      data.pwned[0] && data.pwned[0].stakeholders
        ? data.pwned[0].stakeholders[0].surveyEntityId
        : 0
    );
    state.passworized = extractValueFromProfile(
      data.data,
      "DIMENSION_GROUP_ABS",
      "SURVEY_KYMATIO_BREACH_CORPORATE",
      "dimensionValueExtra",
      state.interactions,
      "DIMENSION_ELEMENT_BREACH_TOTAL",
      data.pwned[0] && data.pwned[0].stakeholders
        ? data.pwned[0].stakeholders[0].surveyEntityId
        : 0,
      true
    );
    state.pwned = data.pwned;
    state.breachesStatus = "success";
  },
  set_archetype(state, data) {
    _.forEach(data.actions, o => {
      if (parseInt(o.actionTypeId) === parseInt(data.dimensions.description)) {
        state.archetype["name"] = { text: o.executeAction.title };
        state.archetype["description"] = {
          text: o.executeAction.description
        };
      } else if (
        parseInt(o.actionTypeId) === parseInt(data.dimensions.motivation)
      ) {
        state.archetype["motivation"] = {
          text: o.executeAction.description,
          assets: o.executeAction.assets
        };
      } else if (parseInt(o.actionTypeId) === parseInt(data.dimensions.work)) {
        state.archetype["work"] = {
          text: o.executeAction.description,
          assets: o.executeAction.assets
        };
      } else if (
        parseInt(o.actionTypeId) === parseInt(data.dimensions.strengths)
      ) {
        state.archetype["strengths"] = {
          text: o.executeAction.description,
          assets: o.executeAction.assets
        };
      } else if (
        parseInt(o.actionTypeId) === parseInt(data.dimensions.challenges)
      ) {
        state.archetype["challenges"] = {
          text: o.executeAction.description,
          assets: o.executeAction.assets
        };
      }
    });
  },
  set_interactions(state, data) {
    state.interactions = data;
    state.pendingInteractions = _.filter(
      data,
      interaction =>
        (interaction.surveyStatus === "AVAILABLE" ||
          interaction.surveyStatus === "PROGRESS") &&
        !_.includes(
          [
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_HELPER"),
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_GAMING"),
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_ABS"),
            store.getters["Constants/getConstant"](
              "SURVEYTYPE_KYMATIO_PHISHING"
            ),
            store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_GLOBAL")
          ],
          parseInt(interaction.surveyFamilyId)
        )
    );
  },
  set_pills(state, data) {
    state.pills = _.map(data, element => {
      return element.executeAction;
    });
  },
  set_custom_pills(state, data) {
    state.customPills = _.map(data, element => {
      return element.executeAction;
    });
  },
  set_done(state) {
    state.status = "success";
  },
  resetBreaches(state) {
    state.breachesStatus = "";
    state.pwned = {};
    state.pwnedStatus = false;
    state.passworized = false;
  },
  reset(state) {
    state.achievements = [];
    state.rankings = [];
    state.status = "";
    state.achievementsStatus = "";
    state.breachesStatus = "";
    state.user = {};
    state.wallet = "";
    state.experience = "";
    state.interactions = [];
    state.cybersecurity = {};
    state.organization = {};
    state.infosec = {};
    state.wellbeing = {};
    state.archetype = {};
    state.profile = {};
    state.pills = [];
    state.pwned = {};
    state.pwnedStatus = false;
    state.assets = [];
    state.passworized = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
