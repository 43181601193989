<template>
  <div id="app">
    <div class="loading" :class="{ 'loading--visible': isLoading }">
      <hollow-dots-spinner
        :animation-duration="2000"
        :dot-size="20"
        :dots-num="3"
        :color="'#0AB783'"
      />
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__slow"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import { info } from "@/api/auth.api";

import moment from "moment-timezone";

import { HollowDotsSpinner } from "epic-spinners";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    HollowDotsSpinner
  },
  data() {
    return {
      stakeholderId: localStorage.getItem("companyId")
    };
  },
  computed: {
    ...mapGetters("Loader", ["isLoading"]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Auth", ["isLoggedIn"]),
    ...mapGetters("Translations", ["areTranslationsLoaded"])
  },
  methods: {
    ...mapActions("Constants", ["loadConstants"]),
    ...mapActions("Translations", ["loadTranslations"])
  },
  watch: {
    isConstantsActive(value) {
      if (value !== true) {
        this.loadConstants();
      }
    },
    isLoggedIn(value) {
      if (value === true) {
        this.loadTranslations();
      }
    }
  },
  mounted() {
    if (!this.areTranslationsLoaded && this.isLoggedIn) {
      this.loadTranslations();
    }
    if (!this.isLoggedIn) {
      info({ subdomain: location.hostname }).then(resp => {
        if (resp.data.records.federated && this.$route.name != "Autologin") {
          window.location.replace(resp.data.records.url);
        }
      });
    }
    if (!this.isConstantsActive) {
      this.loadConstants();
    }
    let locale =
      localStorage.getItem("language") ||
      navigator.language.toLowerCase() ||
      "es-es";
    if (locale.toLowerCase() == "cat") {
      locale = "ca";
    }
    moment.locale(locale);
    moment.relativeTimeRounding(Math.ceil);
  }
};
</script>
<style lang="scss">
// 3rd party plugins css
@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500,600,700,800,900");
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
// Main demo style scss
@import "@/assets/scss/style.scss";
@import "@/assets/scss/generic.scss";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
#app {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fill {
  min-height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  background: $page-bg !important;
  #kt_content {
    z-index: 1;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbf6f0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0%;
  left: 0%;
  opacity: 0;
  pointer-events: none;
  &--visible {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1002;
    opacity: 1;
    .spinner {
      position: absolute;
      left: calc(50% - 100px);
      left: -moz-calc(50% - 100px);
      left: -webkit-calc(50% - 100px);
      left: -o-calc(50% - 100px);
      top: calc(50% - 100px);
      top: -moz-calc(50% - 100px);
      top: -webkit-calc(50% - 100px);
      top: -o-calc(50% - 100px);
      z-index: 10000;
      &:before {
        left: auto;
      }
    }
  }
}
</style>
<style lang="scss" src="@/assets/scss/generic.scss"></style>
