import _ from "lodash";
import store from "@/store";
import router from "@/router";
import { checkSVG } from "@/utils/helpers";
import {
  getHelpInteraction,
  getUserInteraction,
  getHelpersBots
} from "@/api/user/traits.api";
import { getBotConfigBySurveyTypeId } from "@/utils/dictionaries";

const state = {
  surveyId: false,
  firstTimeShown: false,
  chatbot: {},
  next: false,
  questions: [],
  totalelements: 0,
  totalelementsdone: 0,
  survey: {},
  bots: [],
  status: "",
  botsStatus: "",
  extraConfig: {}
};

const getters = {
  extraConfig: state => state.extraConfig,
  isChatbotActive: state => state.status === "active",
  status: state => state.status,
  bots: state => state.bots,
  bot: state => state.chatbot.bot,
  interaction: state => state.chatbot.interaction,
  isLoading: state => state.status === "loading",
  areHelpersBotsLoaded: state => state.botsStatus === "success",
  totalelements: state => state.totalelements,
  totalelementsdone: state => state.totalelementsdone,
  survey: state => state.survey,
  nextSurvey: state => state.next,
  questions: state => state.questions,
  firstTimeShown: state => state.firstTimeShown
};

const actions = {
  loadHelpersBots({ commit }) {
    getHelpersBots().then(resp => {
      let helpersBots = _.map(resp.data.records, bot => {
        return { ...bot, section: "menu" };
      });
      commit("set_bots", helpersBots);
      commit("set_bots_status");
    });
  },
  addNewBot({ commit }, bot) {
    let exist = _.find(state.bots, { surveyTypeId: bot.surveyTypeId });
    if (!exist) {
      commit("set_bots", _.concat(state.bots, bot));
    }
  },
  callBot({ commit, rootGetters, dispatch }, data) {
    if (data.bot) {
      commit("set_chatbot", { bot: data.bot });
      commit("set_loading");
      getHelpInteraction(data.bot.surveyTypeId).then(resp => {
        if (resp.data.records.questions.length > 0) {
          commit("set_survey", {});
          commit("set_questions", resp.data.records.questions);
          commit("set_extra_config", resp.data.records.extraConfig);
          commit("set_next", data.next ? data.next : resp.data.records.next);
          setTimeout(() => {
            commit("set_done");
          }, 500);
        } else if (resp.data.records.next.surveyEntityId) {
          let interactions = rootGetters["Profile/interactions"];
          let survey = _.find(interactions, {
            surveyEntityId: resp.data.records.next.surveyEntityId
          });
          dispatch("callSurvey", survey);
        }
      });
    }
  },
  callSurvey({ commit, rootGetters }, interaction) {
    if (interaction) {
      let hasCustomService = rootGetters["Auth/hasModule"](["custom_surveys"]);
      let botConfig = getBotConfigBySurveyTypeId(interaction.surveyTypeId);
      let familyTypeId = parseInt(interaction.surveyFamilyId);
      let customId = parseInt(
        rootGetters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_CSAAS")
      );
      let cyberId = parseInt(
        rootGetters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_CYBERSECURITY")
      );
      if (interaction.helperSurveyTypeId) {
        let bot = _.find(state.bots, {
          surveyTypeId: interaction.helperSurveyTypeId
        });
        commit("reset");
        commit("set_chatbot", { bot: bot, interaction: interaction });
      }

      if (familyTypeId === cyberId) {
        if (
          store.getters["Auth/hasModule"](["gaming_games"]) &&
          checkSVG(interaction.surveyTypeId)
        ) {
          router.push({
            name: "Cybergaming",
            params: {
              surveyEntityId: interaction.surveyEntityId
            }
          });
        } else {
          router.push({
            name: "Cybertest",
            params: { surveyEntityId: interaction.surveyEntityId }
          });
        }
      } else if (customId === familyTypeId) {
        if (hasCustomService) {
          router.push({
            name: botConfig.bot.name,
            params: {
              surveyTypeId: interaction.surveyTypeId,
              surveyEntityId: interaction.surveyEntityId
            }
          });
        }
      } else {
        getUserInteraction(interaction.surveyEntityId).then(resp => {
          commit("set_questions", resp.data.records.questions);
          commit("set_total_elements", {
            totalelements: resp.data.records.totalelements,
            totalelementsdone: resp.data.records.totalelementsdone
          });
          commit("set_survey", resp.data.records);
          setTimeout(() => {
            commit("set_done");
          }, 1000);
        });
      }
    }
  },
  formatActivateData(surveyEntityId, interactions) {
    let bot = {};
    let survey = _.find(interactions, {
      surveyEntityId: surveyEntityId
    });

    if (survey && survey.helperSurveyTypeId != null) {
      bot = _.find(state.bots, {
        surveyTypeId: survey.helperSurveyTypeId
      });
      return {
        bot: bot,
        mode: "bot",
        next: {
          surveyEntityId: survey.surveyEntityId,
          surveyTypeId: survey.surveyTypeId
        }
      };
    } else {
      return { interaction: survey, mode: "survey" };
    }
  },
  activate({ dispatch, rootGetters }, surveyEntityId) {
    let interaction = {};
    let interactions = rootGetters["Profile/interactions"];

    let data = actions.formatActivateData(surveyEntityId, interactions);

    if (data.mode == "bot") {
      dispatch("callBot", data);
    } else if (data.mode == "survey") {
      interaction = data.interaction;
      dispatch("callSurvey", interaction);
    }
  },
  setFirstTimeShown({ commit }) {
    commit("set_shown");
  },
  addQuestions({ commit }, data) {
    commit("set_questions", data);
  },
  reset({ commit }) {
    commit("reset");
  },
  resetStatus({ commit }) {
    commit("reset_status");
  }
};

const mutations = {
  set_extra_config(state, data) {
    state.extraConfig = data;
  },
  set_chatbot(state, data) {
    state.chatbot = data;
  },
  set_survey_id(state, data) {
    state.surveyId = data;
  },
  set_questions(state, data) {
    state.questions = data;
  },
  set_next(state, data) {
    if (data && Object.keys(data).length > 0) state.next = data;
  },
  set_loading(state) {
    state.status = "loading";
  },
  set_bots(state, data) {
    state.bots = data;
  },
  set_total_elements(state, data) {
    state.totalelements = data.totalelements;
    state.totalelementsdone = data.totalelementsdone;
  },
  set_survey(state, data) {
    state.survey = data;
  },
  set_done(state) {
    state.status = "active";
  },
  set_shown(state) {
    state.firstTimeShown = true;
  },
  set_status(state, data) {
    state.status = data;
  },
  set_bots_status(state) {
    state.botsStatus = "success";
  },
  reset(state) {
    state.surveyId = false;
    state.chatbot = {};
    state.status = "";
    state.next = false;
    state.questions = [];
    state.totalelements = 0;
    state.totalelementsdone = 0;
    state.survey = {};
    state.extraConfig = {};
  },
  reset_status(state) {
    state.status = "";
    state.questions = [];
    state.chatbot = {};
    state.survey = {};
    state.totalelements = 0;
    state.totalelementsdone = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
