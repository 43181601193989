import axios from "axios";
import moment from "moment-timezone";
import store from "@/store";

import { updateUserStakeholder } from "@/api/user/stakeholders.api";

import { getTheme, getStakeholderData } from "@/api/stakeholders.api";
import { getUserInteractions, getUserActions } from "@/api/user/traits.api";
import { assets } from "@/api/auth.api";
import { getConstants } from "@/api/misc.api";

import _ from "lodash";

const state = {
  user: {},
  sessions: [],
  cybersecurity_level: false,
  wellbeing_level: false,
  archetype: "",
  archetypeSvg: false,
  archetypeId: false,
  avatar: "",
  company: {},
  actualBot: {},
  status: "",
  avatars: [
    "004-boy-1.svg",
    "008-boy-3.svg",
    "012-girl-5.svg",
    "016-boy-7.svg",
    "020-girl-11.svg",
    "024-boy-9.svg",
    "028-girl-16.svg",
    "032-boy-13.svg",
    "036-girl-19.svg",
    "040-boy-17.svg",
    "044-boy-19.svg",
    "048-boy-21.svg",
    "052-boy-24.svg",
    "001-boy.svg",
    "005-girl-2.svg",
    "009-boy-4.svg",
    "013-girl-6.svg",
    "017-girl-8.svg",
    "021-boy-8.svg",
    "025-girl-14.svg",
    "029-boy-11.svg",
    "033-girl-18.svg",
    "037-girl-20.svg",
    "041-girl-22.svg",
    "045-boy-20.svg",
    "049-boy-22.svg",
    "002-girl.svg",
    "006-girl-3.svg",
    "010-girl-4.svg",
    "014-girl-7.svg",
    "018-girl-9.svg",
    "022-girl-12.svg",
    "026-boy-10.svg",
    "030-girl-17.svg",
    "034-boy-14.svg",
    "038-boy-16.svg",
    "042-girl-23.svg",
    "046-girl-24.svg",
    "050-girl-26.svg",
    "003-girl-1.svg",
    "007-boy-2.svg",
    "011-boy-5.svg",
    "015-boy-6.svg",
    "019-girl-10.svg",
    "023-girl-13.svg",
    "027-girl-15.svg",
    "031-boy-12.svg",
    "035-boy-15.svg",
    "039-girl-21.svg",
    "043-boy-18.svg",
    "047-girl-25.svg",
    "051-boy-23.svg"
  ]
};

const getters = {
  companyName: state => state.company.name,
  company: state => state.company,
  cybersecurity: state =>
    state.cybersecurity_level
      ? Math.round(state.cybersecurity_level * 10) / 10
      : false,
  progress: state => {
    return _.meanBy(state.sessions, e => {
      return parseFloat(e.percentage);
    });
  },
  dynamicStatus: state => {
    var actualBot = _.find(state.sessions, o => {
      return (
        moment().isAfter(o.userStartDate) &&
        (o.status == "AVALIABLE" || o.status == "PROGRESS")
      );
    });
    var result = "settled";
    if (
      actualBot &&
      actualBot.status == "AVAILABLE" &&
      parseInt(actualBot.surveyFamilyId) ===
        parseInt(
          store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_WELCOME")
        )
    ) {
      result = "welcome";
    } else if (!actualBot) {
      result = "settled";
    } else if (actualBot) {
      result = "pending";
    }
    return result;
  },
  wellbeing: state => state.wellbeing_level,
  archetypeSvg: state => state.archetypeSvg,
  archetypeId: state => state.archetypeId,
  sessions: state =>
    _.orderBy(
      _.filter(
        state.sessions,
        session =>
          parseInt(session.surveyFamilyId) !==
            parseInt(
              store.getters["Constants/getConstant"](
                "SURVEYTYPE_KYMATIO_PHISHING"
              )
            ) &&
          parseInt(session.surveyFamilyId) !==
            parseInt(
              store.getters["Constants/getConstant"]("SURVEYTYPE_KYMATIO_ABS")
            )
      ),
      [
        e => {
          let val = 100;
          switch (e.surveyStatus) {
            case "AVAILABLE":
            case "UNAVAILABLE":
              val = 0;
              break;
            case "PROGRESS":
              val = 50;
              break;
          }
          return val;
        },
        e => {
          var thedate = moment(e.dateStatus).format("x");
          return thedate;
        }
      ],
      ["asc", "desc"]
    ),
  interactions: state =>
    _.orderBy(
      state.sessions,
      [
        e => {
          let val = 100;
          switch (e.surveyStatus) {
            case "AVAILABLE":
            case "UNAVAILABLE":
              val = 0;
              break;
            case "PROGRESS":
              val = 50;
              break;
          }
          return val;
        },
        e => {
          return moment(e.userStartDate).format("x");
        }
      ],
      ["asc", "desc"]
    ),
  user: state => state.user,
  archetype: state => state.archetype,
  actualBot: state => (_.isEmpty(state.actualBot) ? false : state.actualBot),
  fullname: state => `${state.user.name || ""} ${state.user.surname || ""}`,
  avatar: state =>
    state.avatar !== ""
      ? state.avatar
      : state.avatars[Math.floor(Math.random() * (state.avatars.length - 1))],
  isUserLoaded: state => state.status !== "",
  defaultAvatar: state => state.avatar === "",
  avatars: state => state.avatars
};

const actions = {
  loadUser({ commit, rootGetters }) {
    var actionParams = {
      groupDimensions: [
        rootGetters["Constants/getConstant"]("DIMENSION_GROUP_ARCHETYPES")
      ],
      actionTypes: [
        rootGetters["Constants/getConstant"](
          "ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"
        )
      ],
      entityType: rootGetters["Constants/getConstant"]("ENTITY_TYPE_USER"),
      surveyTypes: [
        rootGetters["Constants/getConstant"]("SURVEY_KYMATIO_ARCHETYPE_GLOBAL")
      ]
    };
    getTheme(localStorage.getItem("companyId")).then(response => {
      commit("set_theme", response.data.records);
    });
    if (_.includes(_.words(localStorage.getItem("profiles")), "USER")) {
      let requests = [
        getStakeholderData({
          email: true,
          avatar: true,
          login: true
        }),
        getUserInteractions(false),
        getUserActions(actionParams),
        getConstants("SURVEY_KYMATIO_PHISHING")
      ];

      axios.all(requests).then(
        axios.spread(
          (stakeholderResp, sessionsResp, actionsResp, constantsResp) => {
            if (
              actionsResp.data.records.length > 0 &&
              actionsResp.data.records[0].executeAction &&
              actionsResp.data.records[0].executeAction.assets &&
              actionsResp.data.records[0].executeAction.assets.length > 0
            ) {
              assets(actionsResp.data.records[0].executeAction.assets[0].url)
                .then(resp => {
                  commit(
                    "set_archetype_image",
                    resp.data.records.content || false
                  );
                })
                .catch(() => {
                  commit("set_archetype_image", false);
                });
            }
            commit("set_stakeholder", stakeholderResp.data.records);
            commit("set_sessions", {
              data: sessionsResp.data.records,
              interactions: _.values(constantsResp.data.records)
            });
            commit("set_archetype", actionsResp.data.records);
            commit("set_done");
          }
        )
      );
    } else {
      getStakeholderData({
        email: true,
        avatar: true,
        login: true
      }).then(stakeholderResp => {
        commit("set_stakeholder", stakeholderResp.data.records);
      });
    }
  },
  reset_bot({ commit, rootGetters }) {
    let bot = _.find(rootGetters["Chatbot/bots"], { actualBot: 1 });
    commit("set_actual_bot", bot);
  },
  saveAvatar({ commit }, avatar) {
    updateUserStakeholder({
      avatar: avatar
    }).then(() => {
      commit("set_avatar", avatar);
    });
  },
  reset({ commit }) {
    commit("reset");
  }
};

const mutations = {
  set_stakeholder(state, data) {
    state.user = data;
    state.avatar = data.avatar || state.avatar;
  },
  set_company(state, data) {
    state.company = data;
  },
  set_theme(state, data) {
    state.company = data;
  },
  set_actual_bot(state, data) {
    state.actualBot = data;
  },
  set_sessions(state, data) {
    let sessions = _.filter(data.data, session => {
      return !_.includes(data.interactions, parseInt(session.surveyTypeId));
    });
    state.sessions = sessions;
    store.dispatch("User/reset_bot");
  },
  set_archetype(state, data) {
    state.archetype = data.length > 0 ? data[0].executeAction.title : "";
    state.archetypeId = data.length > 0 ? data[0].dimensionId : "";
  },
  set_archetype_image(state, data) {
    state.archetypeSvg = data;
  },
  set_done(state) {
    state.status = "success";
  },
  set_avatar(state, avatar) {
    state.avatar = avatar;
  },
  reset(state) {
    state.user = {};
    state.sessions = [];
    state.cybersecurity_level = false;
    state.wellbeing_level = false;
    state.archetype = "";
    state.archetypeSvg = false;
    state.archetypeId = false;
    state.status = "";
    state.avatar = "";
    state.company = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
